import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './App.css';
import { darkTheme } from './theme/Themes';
import Engine from './view/Engine';
import Games from './view/Games';
import Landing from './view/Landing';
import NotFound from './view/NotFound';
import Projects from './view/Projects';
import Thanks from './view/download/Thanks';
import Download from './view/kitchen_corner/Download';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/projects',
    element: <Projects />
  },
  {
    path: '/games',
    element: <Games />
  },
  {
    path: '/engine',
    element: <Engine />
  },
  {
    path: '/kitchencorner/download',
    element: <Download />
  },
  {
    path: '/thanks',
    element: <Thanks />
  },
  {
    path: '/*',
    element: <NotFound />
  }
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
