import { Container, Typography } from '@mui/material';
import React from 'react';

import Logo from '../Logo';
import Column from './Column';

export default function SmallFooter() {
  return (
    <Container
      style={{
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          paddingBottom: '3%',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5em'
          }}
        >
          <Column
            name='Download'
            links={[
              {
                name: 'Engine',
                navigation: '/'
              },
              {
                name: 'Project',
                navigation: '/'
              },
              {
                name: 'Game',
                navigation: '/'
              }
            ]}
          />
          <Column
            name='About'
            links={[
              {
                name: 'Features',
                navigation: '/'
              },
              {
                name: 'License',
                navigation: '/'
              },
              {
                name: 'Code of Conduct',
                navigation: '/'
              }
            ]}
          />
        </div>
        <a className='titleLink' href={'mailto:contact@creative-rift.com'}>
          Contact Us
        </a>
      </div>
      <div
        style={{
          marginBottom: '2vh',
          textAlign: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            position: 'relative'
          }}
        >
          <Logo />
        </div>
        <Typography variant='h4'>Copyright © 2020 - 2023</Typography>
        <Typography variant='h4'>Guillaume S.</Typography>
      </div>
    </Container>
  );
}
