import { Container, Typography } from '@mui/material';
import React from 'react';

export default function Future() {
  return (
    <Container
      style={{
        marginTop: '3vh',
        marginBottom: '3vh'
      }}
    >
      <Typography variant='h2' id='about' style={{ marginBottom: '1vh' }}>
        What&apos;s next?
      </Typography>
      <Typography variant='body1'>I have three main objectives this year:</Typography>
      <Typography variant='body1'>
        &#x2022; Finish Kitchen Corner, currently in beta the game need a lot of polish and needs more levels (goal of 9 levels).
      </Typography>
      <Typography variant='body1'>&#x2022; Finish the new version of my Game Engine with a UI interface to create easier games.</Typography>
      <Typography variant='body1'>
        &#x2022; Continue to work on different projects like Modding games, write tools for game development, Shaders and other...
      </Typography>
    </Container>
  );
}
