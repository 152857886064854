import { Alert, Slide, SlideProps, Snackbar } from '@mui/material';
import React from 'react';

function TransitionSlide(props: SlideProps) {
  return <Slide {...props} direction='down' />;
}

export default function SnackBar(props: { CloseCallback: () => void; open: boolean; message: string }) {
  const { CloseCallback, open, message } = props;

  const handleClose = () => {
    CloseCallback();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      key={'topcenter'}
      TransitionComponent={TransitionSlide}
      autoHideDuration={6000}
    >
      <Alert onClose={handleClose} severity='error'>
        {message || 'Default message'}
      </Alert>
    </Snackbar>
  );
}
