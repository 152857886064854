import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../App.css';
import Logo from '../Logo';

export function Tab(props: { name: string; navigation: string }) {
  const { name, navigation } = props;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(navigation);
      }}
      style={{
        marginTop: '2vh',
        marginBottom: '2vh',
        cursor: 'pointer'
      }}
    >
      <a href={navigation} className='titleLinkNoDeco'>
        {name}
      </a>
    </div>
  );
}

export default function NavDrawer() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuIcon
          style={{
            color: '#73A9F3'
          }}
        />
      </IconButton>
      <Drawer
        anchor={'top'}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          style={{
            background: '#102036',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '3vw'
          }}
        >
          <div
            style={{
              marginTop: '1vh',
              marginBottom: '2vh'
            }}
          >
            <Logo />
          </div>
          <Tab name='Engine' navigation='/engine' />
          <Tab name='Projects' navigation='/projects' />
          <Tab name='Games' navigation='/games' />
        </Box>
      </Drawer>
    </div>
  );
}
