import { Avatar, Button, Container, Typography, useTheme } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import Footer from '../Component/footer/Footer';
import NavBar from '../Component/navbar/NavBar';
import KCImage from '../resources/KC_Main.png';
import Moul from '../resources/bomb_icon.png';

function PresentationTile(props: {
  projectName: string;
  image: string;
  desc: string;
  learnEnabled?: boolean | undefined;
  dlEnabled?: boolean | undefined;
}) {
  const { projectName, image, desc, learnEnabled, dlEnabled } = props;
  const navigation = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5vh' }}>
      <div style={{ marginRight: '3vw' }}>
        <Avatar alt={projectName + ' image'} src={image} sx={{ width: 200, height: 200 }} />
      </div>
      <div style={{ marginTop: '3vh' }}>
        <Typography variant='h2'>{projectName}</Typography>
        <Typography variant='body1'>{desc}</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1vh' }}>
          {learnEnabled !== undefined && (
            <Button variant='contained' style={{ marginRight: '2vw' }} disabled={!learnEnabled}>
              Learn More
            </Button>
          )}
          {dlEnabled !== undefined && (
            <Button
              color='secondary'
              variant='contained'
              onClick={() => navigation('/' + projectName.toLowerCase().replace(' ', '') + '/download')}
              disabled={!dlEnabled}
            >
              Download
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default function Games() {
  const { palette } = useTheme();

  return (
    <div
      style={{
        background: palette.background.paper
      }}
    >
      <NavBar />
      <Container
        style={{
          textAlign: 'left',
          maxWidth: '40em',
          position: 'relative',
          zIndex: '1',
          paddingTop: '150px',
          paddingBottom: '360px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <PresentationTile
          projectName={'Kitchen Corner'}
          image={KCImage}
          desc='Kitchen corner is a multiplayer game. Your goal is to cook some deliciouse food and be the kitchen master'
          learnEnabled={false}
          dlEnabled={true}
        />
        <PresentationTile projectName={'Moulmerman'} image={Moul} desc='Coming Soon...' />
      </Container>
      <Footer />
    </div>
  );
}
