import React from 'react';
import AppleIcon from '../../resources/icon_apple.svg';
import LinuxIcon from '../../resources/icon_linux.svg';
import WindowsIcon from '../../resources/icon_windows.svg';

export function GetIconPlatform() {
  const userAgent = window.navigator.userAgent;
  let os = WindowsIcon;

  if (userAgent.indexOf('Mac') != -1) os = AppleIcon;
  if (userAgent.indexOf('X11') != -1) os = LinuxIcon;
  if (userAgent.indexOf('Linux') != -1) os = LinuxIcon;
  return (
    <img
      src={os}
      alt='logo'
      style={{
        height: '35px',
        fontSize: '20px'
      }}
    />
  );
}

export function GetPlatformName(): string {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Win') != -1) return 'Windows';
  if (userAgent.indexOf('Mac') != -1) return 'MacOS';
  if (userAgent.indexOf('X11') != -1) return 'Linux';
  if (userAgent.indexOf('Linux') != -1) return 'Linux';
  return 'Unknown OS';
}
