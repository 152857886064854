import { Container, Typography } from '@mui/material';
import React from 'react';

export default function About() {
  return (
    <Container
      style={{
        marginTop: '3vh',
        marginBottom: '3vh'
      }}
    >
      <Typography variant='h2' id='about' style={{ marginBottom: '1vh' }}>
        About Me
      </Typography>
      <Typography variant='body1'>
        Hello sailor, I&apos;m CptnFish and welcome to my ship Creative Rift. Here you can my all my project related to games. You&apos;ll
        find several sections that you can discover, first is the main project: <a href='/engine'>ShipWreck Engine</a>. ShipWreck Engine is
        a game engine to create 2D and 3D games. Then you can all others <a href='/projects'>Project</a> I&apos;ve made, all related to
        games tools the goal is to discover how these tools work. Finally, all my <a href='/games'>Games</a>, made by myself or with my
        friends you can download them and enjoy it alone or with your friends.
      </Typography>
    </Container>
  );
}
