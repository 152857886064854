import { Typography } from '@mui/material';
import React from 'react';

import logo from '../resources/creative_logo.svg';

export default function Logo() {
  return (
    <a
      href={'/'}
      style={{
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <img
        src={logo}
        alt='logo'
        style={{
          height: '48px',
          fontSize: '20px'
        }}
      />
      <Typography
        style={{
          color: '#73A9F3',
          fontSize: '20px',
          fontWeight: '400',
          fontFamily: "'Courgette', cursive"
        }}
      >
        Creative Rift
      </Typography>
    </a>
  );
}
