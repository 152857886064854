import { useMediaQuery } from '@mui/material';
import React from 'react';

import BigFooter from './BigFooter';
import SmallFooter from './SmallFooter';

export default function Footer() {
  const matches = useMediaQuery('(min-width:800px)');

  return (
    <footer
      style={{
        paddingTop: '2vh'
      }}
    >
      {matches ? <BigFooter /> : <SmallFooter />}
    </footer>
  );
}
