import { Typography } from '@mui/material';
import React from 'react';

import '../../App.css';

type LinkObject = { name: string; navigation: string };

type LinkValue = Array<LinkObject>;

export default function Column(props: { name: string; links: LinkValue }) {
  const { name, links } = props;

  return (
    <div
      style={{
        gap: '19px',
        display: 'inline-flex',
        flexDirection: 'column',
        paddingBottom: '5vh'
      }}
    >
      <Typography variant='h2'>{name}</Typography>
      {links &&
        links.map((value: LinkObject, index: number) => {
          return (
            <a key={index} className='link' href={value.navigation}>
              {value.name}
            </a>
          );
        })}
    </div>
  );
}
